import md5 from 'md5'

const ksort = (obj) => {
  const keys = Object.keys(obj).sort()
  const sortedObj = {}
  for (const i in keys) {
    sortedObj[keys[i]] = obj[keys[i]]
  }
  return sortedObj
}

const getSign = (obj) => {
  const sortedObj = ksort(obj)
  let str = ''
  const keys = Object.keys(sortedObj)
  keys.forEach((key) => {
    str = str.concat(key, '=', sortedObj[key], '&')
  })
  const privatekey = 'cd7fe6a9d92b2f4c17b363499ce589f5'
  str = str.concat(privatekey)
  const sign = md5(md5(str).toString()).toString()
  return sign
}

const def = () => false

export default def
export {
  getSign
}

export const setLocalStorage = (key, val) =>
  window.localStorage.setItem(key, val)

export const getLocalStorage = key => window.localStorage.getItem(key)

export const removeLocalStorage = key => window.localStorage.removeItem(key)

const zenDeskCallback = (locale, defaultChatGroup) => {
  // init zenDesk widget when ready.
  if (typeof zE !== 'undefined') {
    zE(function () {
      // const newLocale = locale === 'zh' ? 'en' : locale
      zE('webWidget', 'updateSettings', {
        webWidget: {
          // color: { theme: '#78a300' },
          contactForm: {
            // title: {
            //   '*': 'Feedback'
            // },
            ticketForms: [
              { id: defaultChatGroup }
            ]
          }
        },
        cookies: false
      })
      // change website language not change ticket language now 20240511 - lucas
      zE.setLocale(locale || 'en')
      zE.hide()
      const supportBtn = document.getElementById('supportBtn')
      if (supportBtn) { supportBtn.style.display = 'block' }
      zE('webWidget:on', 'open', function () {
        zE.show()
        zE('webWidget', 'open')
      })
      zE('webWidget:on', 'close', function () {
        zE.hide()
      })
    })
  }
}

export const zenDeskFunc = (locale) => {
  const zenDeskChannelId = '199c940a-0e3e-4079-a474-c775b52fcb79'
  let defaultChatGroup = 26415773889556
  // Pagsmile English - 26415773889556
  // Pagsmile LATAM - 26335605183124 es
  // Pagsmile BR - 25766131541140
  if (locale === 'en') {
    defaultChatGroup = 26415773889556
  } else if (locale === 'es') {
    defaultChatGroup = 26335605183124
  } else if (locale === 'pt') {
    defaultChatGroup = 25766131541140
  // } else if (locale === 'zh') {
  //   defaultChatGroup = 26415773889556
  }
  // console.log(`===========${defaultChatGroup}===============`, locale)
  window.zESettings = {
    webWidget: {
      // color: { theme: '#78a300' },
      contactForm: {
        // title: {
        //   '*': 'Feedback'
        // },
        ticketForms: [
          { id: defaultChatGroup }
        ]
      }
    },
    cookies: false
  }
  // 移除旧的script标签
  const oldScript = document.querySelector('script[id="ze-snippet"]')
  if (oldScript) {
    oldScript.parentNode.removeChild(oldScript)
  }

  const zenDesk = document.createElement('script')
  zenDesk.type = 'text/javascript'
  zenDesk.id = 'ze-snippet'
  zenDesk.async = true
  zenDesk.src =
    (document.location.protocol === 'https:' ? 'https://' : 'http://') +
    `static.zdassets.com/ekr/snippet.js?key=${zenDeskChannelId}`

  const supportBtn = document.getElementById('supportBtn')
  if (supportBtn) { supportBtn.style.display = 'none' }

  const s = document.getElementsByTagName('script')[0]
  s.parentNode.insertBefore(zenDesk, s)
  if (zenDesk.readyState) { // Script ready for ie
    zenDesk.onreadystatechange = function () {
      if (zenDesk.readyState === 'loaded' || zenDesk.readyState === 'complete') {
        zenDesk.onreadystatechange = null
        zenDeskCallback(locale, defaultChatGroup)
      }
    }
  } else { // Script ready for other browsers
    zenDesk.onload = function () {
      zenDeskCallback(locale, defaultChatGroup)
    }
  }
}
